export default {
  languageLink: 'Español',
  signIn: 'Sign in',
  name: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  password: 'Password',
  gender: 'Gender',
  homeZipCode: 'Home ZIP Code',
  dateOfBirth: 'Date of Birth',
  month: 'Month',
  day: 'Day',
  year: 'Year',
  submitButton: 'Submit',
  retryButton: 'Retry',
  continueButton: 'Continue',
  yes: 'Yes',
  no: 'No',
  noMatchFound: 'No match found',
  tryAgain: 'Try again',
  contactSupport: 'Contact Support',
  'monikers.activateAccount': 'Activate Your Account',
  'monikers.instructions':
    "Please enter your member ID and date of birth. If you are not the primary subscriber on your health plan, please enter the subscriber's member ID and your date of birth.",
  'monikers.alternateLink': 'Try another way',
  'monikers.ineligibleError.text':
    'We are unable to confirm your benefit information with the details provided. You can always {alternateLink} in case details provided are correct to the best of your knowledge.',
  'monikers.ineligibleError.alternateLink': 'try another way',
  'monikersRegister.heading': "Let's complete your account",
  'register.benefitProvider': 'Benefit Provider',
  'register.welcomeHeading': 'Welcome',
  'register.createAccountHeading': "Let's create your account",
  'register.completeProfile': 'Complete Profile',
  'register.checkCoverage': 'Check Coverage',
  'register.dateOfBirth.placeholder':
    'Enter your date of birth, you can add your family members later.',
  'register.agreeToTerms':
    "Clicking Create account means that you agree to MDLIVE's {termsLink} and {privacyLink}",
  'register.termsOfUse': 'Terms of Use',
  'register.privacyPolicy': 'Privacy Policy',
  'register.createAccount': 'Create account',
  'register.alreadyHaveAccount': 'Already have an account?',
  'register.addDependent': 'Want to add a dependent to an existing account?',
  'register.eligibleLogin.foundYouHeader': "We've found you!",
  'register.eligibleLogin.finalizeAccountHeader':
    "We're finalizing your account",
  'register.eligibleLogin.body':
    "You're eligible for coverage with {affiliationName}.",
  'register.createAccountAffiliationHeader': "Let's complete your account",
  'register.dependantsAnnouncement':
    'If signing up on behalf of your dependent, create your own account and add them after you sign up.',
  'register.email': "What's your email?",
  'register.password': 'Create a password',
  'register.firstName': "What's your first name?",
  'register.lastName': "What's your last name?",
  'register.dateOfBirth': "What's your date of birth?",
  'register.phone': "What's your phone number?",
  'register.homeZipCode': "What's your home ZIP code?",
  'register.gender': "What's your gender?",
  'register.gender.female': 'Female',
  'register.gender.male': 'Male',
  'register.gender.nonBinary': 'Non-Binary',
  'register.memberId': 'Insurance Member ID',
  'register.isDependent': 'Are you insured as a dependent?',
  'register.skipButton': 'Skip (You can add coverage later)',
  'month.january': 'January',
  'month.february': 'February',
  'month.march': 'March',
  'month.april': 'April',
  'month.may': 'May',
  'month.june': 'June',
  'month.july': 'July',
  'month.august': 'August',
  'month.september': 'September',
  'month.october': 'October',
  'month.november': 'November',
  'month.december': 'December',
  'accountRecovery.alreadyRegistered':
    "It looks like you're already registered",
  'accountRecovery.sendCodeInstructions':
    'To verify your identity, we need to send an authentication code that will be used to sign in.',
  'accountRecovery.invalidPhoneError':
    'We do not have your phone number. Please select email and re-submit.',
  'accountRecovery.verificationError':
    'There was an error verifying your account with that information.',
  'accountRecovery.phone': 'phone',
  'accountRecovery.email': 'email',
  'accountRecovery.or': 'or',
  'accountRecovery.receiveCode': 'How would you like to receive it?',
  'accountRecovery.emailOption': 'Email {email}',
  'accountRecovery.smsOption': 'Text {phone}',
  'accountRecovery.noneOption': 'I can no longer access this {accessOptions}',
  'accountRecovery.sendCodeButton': 'Send Code',
  'accountRecovery.smsCheckAccess': 'Check your phone',
  'accountRecovery.emailCheckAccess': 'Check your email',
  'accountRecovery.smsAccessInstructions':
    'We sent a text message to {contact} with a code. Enter the code below to sign in.',
  'accountRecovery.emailAccessInstructions':
    'We sent an email message to {contact} with a code. Enter the code below to sign in.',
  'accountRecovery.authenticationCode': 'Authentication Code',
  'accountRecovery.5digitCode': '5-digit code',
  'accountRecovery.verifyButton': 'Verify',
  'accountRecovery.codeExpires':
    'The code expires 10 minutes after you request it.',
  'accountRecovery.noAuthenticationCode': "Didn't get an authentication code?",
  'accountRecovery.resendCodeTitle': 'Re-send Code',
  'accountRecovery.resendCodeBody': 'Get a new verification code',
  'accountRecovery.smsAlternateMethodTitle': 'Text Me',
  'accountRecovery.smsAlternateMethodBody': 'Get a sms with a new code',
  'accountRecovery.emailAlternateMethodTitle': 'Email Me',
  'accountRecovery.emailAlternateMethodBody': 'Get an email with a new code',
  'accountRecovery.contactSupportTitle': 'Contact Support',
  'accountRecovery.contactSupportBody':
    'Connect with an MDLIVE Health Service Specialist',
  'accountRecovery.contactSupportAriaLabel': 'Instructions to contact support',
  'accountRecovery.contactSupportModalBody':
    'Connect with a MDLIVE Health Service Specialist at {supportNumber} to recover your account.',
  'accountRecovery.incorrectAuthCodeError':
    'Authorization Code is incorrect or expired. Please re-send the code.',
  'accountRecovery.codeResent': 'Code sent successfully',
  'accountRecovery.codePhoneResent':
    'Code will be delivered through phone call',
  'accountRecovery.resendCodeError': 'There was an error re-sending the code.',
  'affiliationGroupError.notEligible': `We are unable to verify your eligibility with {selectedAffiliationName}.
        However, we found some possible matches for you. Please select from one of
        the insurance providers, employers or benefit groups below to continue.`,
  'validations.alphaNumeric': 'Only alphanumeric characters',
  'validations.minLength': 'Must be {min} characters or more',
  'validations.maxLength': 'Must be {max} characters or less',
  'validations.exactLength': 'Must be {target} characters',
  'validations.exactLengthDigits': 'Must be {target} digits',
  'validations.required': 'Required',
  'validations.requiredArray': 'Required',
  'validations.requiredLetter': 'Must have at least 1 letter',
  'validations.requiredNumber': 'Must have at least 1 number',
  'validations.requiredUppercase': 'Must have at least 1 uppercase letter',
  'validations.requiredLowercase': 'Must have at least 1 lowercase letter',
  'validations.requiredSpecialCharacter':
    'Must have at least 1 special character',
  'validations.allLetters': 'Please enter letters only',
  'validations.allNumbers': 'Please enter numbers only',
  'validations.validDate': 'Invalid date',
  'validations.email': 'Invalid email address',
  'validations.phone': 'Must be a valid phone number',
  'validations.before1900': 'Invalid date',
  'validations.yearAfter1900': 'Invalid year',
  'validations.yearInThePast': 'Must be a past year',
  'validations.beforeNow': 'Must be a past date',
  'validations.zipCode': 'Invalid zip code',
  'validations.validDay': 'Please enter a day between 1 and {maxDay}',
  'validations.gender': 'Gender required',
  'validations.lenghBetween': 'Must be between 8 and 20 characters',
  'emailFieldTooltip.header': 'Your Email',
  'emailFieldTooltip.body': "You'll use this to sign in",
  'eligibilityError.ariaLabel': 'Reason for eligibility error',
  'eligibilityError.heading': "We weren't able to verify your eligibility",
  'eligibilityError.notEligibleBody':
    "We apologize, but we cannot confirm your eligibility information at this time. Please contact your benefit administrator to verify your eligibility. You can also click 'Retry' to re-submit your information.",
  'eligibilityError.continueWithoutCoverageBody':
    'To confirm your benefits coverage, please contact your health plan directly. If you click on ‘Continue without Coverage’ you can still see a provider and update insurance information at a later time.',
  'eligibilityError.continueWithoutCoverageButton': 'Continue without Coverage',
  'eligibilityError.callSupport': 'Call Support: {phone}',
  'eligibilityError.retry': 'Retry',
  'error.unexpectedError': 'There was an unexpected error.',
  'lockedUserError.toaster':
    'User account is Locked. Please {unlockLink} to reset your password.',
  'lockedUserError.clickHere': 'Click Here',
  'lockedUserError.modalAriaLabel': 'Instructions to unlock account',
  'lockedUserError.modalHeader': 'Password Reset',
  'lockedUserError.modalAccountLocked':
    'To help keep your information safe, your account has been locked.',
  'lockedUserError.modalReceiveCode':
    'We will send you a code to ensure the security of your account. Select the method in which you would prefer to receive the code.',
  'lockedUserError.modalVerificationMethod': 'verification method',
  'lockedUserError.modalSendLinkButton': 'Send Link',
  'multipleUserAccountError.alert':
    'We found multiple accounts with that information. Please select your current benefit provider below and re-submit.',
  'multipleUserAccountError.label':
    'What insurance provider, employer or benefit group do you belong to?',
  'userZipError.alert':
    '<bold>Recently moved?</bold> We may have your previous ZIP code on file. Update your ZIP code to recover your account.',
  'eligibilityCheckZipError.alert':
    '<bold>Recently moved?</bold> We may have your previous ZIP code on file. Update your ZIP code to create your account.',
  'passwordRecovery.headline': 'Password Recovery',
  'passwordRecovery.sms':
    'Please enter the authentication code we just texted to {connectionValue}.',
  'passwordRecovery.phone':
    'Please enter the authentication code we just sent to {connectionValue}.',
  'passwordRecovery.email':
    'Please enter the authentication code we just sent to the Email {connectionValue}.',
  'passwordRecovery.codeValidTime': 'The code will be valid for 10 minutes.',
  'passwordRecovery.invalidCode': 'Invalid code',
  'passwordRecovery.reEnterOrResend':
    'You can try re-entering the code or resend the code.',
  'passwordRecovery.resendOrTryAnotherWay': 'Resend code or try another way',
  'passwordRecovery.resendCode': 'Resend code',
  'passwordRecovery.getNewCode': 'Get a new code',
  'passwordRecovery.phoneCall': 'Phone call',
  'passwordRecovery.changePassword': 'Change password',
  'passwordRecovery.createStrongPassword': 'Create a strong new password',
  'password.newPasswordLabel': 'New Password',
  'password.confirmNewPasswordLabel': 'Confirm New Password',
  'login.signIn': 'Sign in to your account',
  'login.signingYouIn': 'Signing you in...',
  'login.verifyAccountMessage.email':
    'We need to make sure this account belongs to you. We have sent an authentication code to your Email {connectionValue}. Please enter the code below.',
  'login.verifyAccountMessage.sms':
    'We need to make sure this account belongs to you. We have sent an authentication code to your phone {connectionValue}. Please enter the code below.',
  'login.resendCode': 'Resend code',
  'login.selectDifferentAccount': 'Select different account',
  'login.LastSignedIn': 'Last signed in',
  'login.invalidCode': 'Invalid Code',
  'login.resendCodeMessage':
    'You can try re-entering the code or resend the code',
  'login.accountRecovery.headline': 'Forgot Username',
  'login.accountRecovery.subHeadline':
    'Enter some basic details so we can retrieve your account',
  'login.accountRecovery.multiple.chooseAnAccount': 'Choose an account',
  'login.accountRecovery.failed.invalidPhoneError':
    'We do not have your phone number.',
  'login.accountRecovery.failed.findAccount': 'Unable to find your account?',
  'login.accountRecovery.failed.tooManyRequests':
    "We're sorry, but you have sent too many requests. Please try again later.",
  'login.accountRecovery.accountBelongsYou':
    'We need to make sure this account belongs to you.',
  'login.accountRecovery.enterCodeBelow':
    'Please enter the code below. The code will be valid for 10 minutes.',
  'login.accountRecovery.sms':
    'We have texted an authentication code to the number associated with this account {connectionValue}.',
  'login.accountRecovery.phone':
    'We have called you to sent an authentication code to the number associated with this account {connectionValue}.',
  'login.accountRecovery.email':
    'We have sent an authentication code to the Email associated with this account {connectionValue}.',
  'login.accountRecovery.accountNotFound': 'Account not found',
  'login.accountRecovery.signIn': 'Sign in to your account',
  'login.accountRecovery.accountNotFoundDescription':
    'We apologize. We were unable to find your account with the information you provided. Check for typos and try again.',
  'login.updatePassword.error': 'Unable to change your password.',
  'login.label.usernameOrEmail': 'Username or Email',
  'login.forgotUsernameLink': 'Forgot username',
  'login.forgotPasswordLink': 'Forgot Password',
  'login.orOption': 'or',
  'login.customSignInSeamlessSso': 'Sign in With ',
  'accountLocked.headline': 'Your account has been locked',
  'accountLocked.sms':
    'We have sent an authentication code to the phone number associated with this account {connectionValue}.',
  'accountLocked.phone':
    'Please enter the authentication code we just sent to {connectionValue}.',
  'accountLocked.email':
    'Please enter the authentication code we just sent to the Email {connectionValue}.',
  'accountLocked.codeValidTime':
    'Enter the code to unlock your account. The code will be valid for 10 minutes',
  'accountLocked.invalidCode': 'Invalid code',
  'accountLocked.reEnterOrResend':
    'You can try re-entering the code or resend the code.',
  'accountLocked.resendOrTryAnotherWay': 'Resend code or try another way',
  'accountLocked.resendCode': 'Resend code',
  'accountLocked.getNewCode': 'Get a new code',
  'accountLocked.phoneCall': 'Phone call',
  'accountLocked.changePassword': 'Change password',
  'accountLocked.createStrongPassword': 'Create a new password',
  'accountLocked.noAuthenticationCode': 'Resend code or try another way',
};
