import React, { FunctionComponent, ReactElement } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { createColorStyles } from 'mdlkit';
import { BrandingColors } from '../../interfaces/branding';

export const formatColor = (color: string) => color.replace(';', '');

const mapAffiliationTheme = (
  defaultTheme: DefaultTheme,
  brandingColors: BrandingColors
): DefaultTheme => {
  const primary = formatColor(brandingColors.firstColor);
  const secondary = formatColor(brandingColors.secondColor);
  const tertiary = formatColor(brandingColors.thirdColor);
  const activeMenu = formatColor(brandingColors.activeMenuColor);
  const text = formatColor(brandingColors.bodyFontColor);
  const primaryText = formatColor(brandingColors.menuFontColor);
  const background = formatColor(brandingColors.pageBackground);
  const headingFont = `'Open Sans',sans-serif`;
  const textFont = `'Open Sans',sans-serif`;

  const colors = {
    ...defaultTheme.colors,
    primary,
    secondary,
    tertiary,
    text,
    primaryText,
    activeMenu,
  };

  return {
    ...defaultTheme,
    headingFont,
    textFont,
    primary,
    secondary,
    tertiary,
    text,
    background,
    foreground: primary,
    colors,
    colorStyles: createColorStyles({ colors }),
  };
};

interface Props {
  children: ReactElement;
  defaultTheme: DefaultTheme;
  brandingColors?: BrandingColors;
}

const AffiliationThemeProvider: FunctionComponent<Props> = ({
  children,
  defaultTheme,
  brandingColors,
}: Props) => {
  const theme =
    typeof brandingColors !== 'undefined'
      ? mapAffiliationTheme(defaultTheme, brandingColors)
      : defaultTheme;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AffiliationThemeProvider;
