import { getAuthTokenAsync } from '../../utils/authToken';
import apiFetch from '../core/apiFetch';
import { checkStatus, handleError } from '../../utils/api';
import { Branding, BrandingApi } from '../../interfaces/branding';

interface Response {
  branding: Branding | null;
}

const mapBranding = ({
  colors,
  content,
  options,
  affiliation,
  paths,
  custom_sign_in,
  ...rest
}: BrandingApi): Branding => {
  const {
    disable_ineligible_member_registration,
    ...restAffiliation
  } = affiliation;
  const { menu, footer } = content;
  const { powered_by, ...restFooter } = footer;

  return {
    affiliation: {
      disableIneligibleMemberRegistration: disable_ineligible_member_registration,
      ...restAffiliation,
    },
    colors: {
      firstColor: colors.first_color,
      secondColor: colors.second_color,
      thirdColor: colors.accent_color || colors.second_color,
      activeMenuColor: colors.third_color,
      footerBackground: colors.footer_background,
      footerFontColor: colors.footer_font_color,
      menuFontColor: colors.menu_font_color,
      bodyFontColor: colors.body_font_color,
      pageBackground: colors.page_background,
    },
    options: {
      translationEnabled: options.translation_enabled,
    },
    content: {
      menu: {
        home: menu.lang_menu_home,
        about: menu.lang_menu_aboutus,
        faq: menu.lang_menu_faq,
        connectNow: menu.lang_menu_connectnow,
        contactUs: menu.lang_menu_contactus,
        signIn: menu.lang_menu_signin,
      },
      footer: {
        poweredBy: powered_by,
        ...restFooter,
      },
    },
    paths: {
      register: paths.register,
    },
    customSignIn: {
      seamlessSso: custom_sign_in.seamless_sso,
      customSignInUrl: custom_sign_in.custom_sign_in_url,
      customSignInAffName: custom_sign_in.custom_sign_in_aff_name,
    },
    ...rest,
  };
};

const getAffiliationBrandingAsync = async (
  affiliationName: string,
  locale: string
): Promise<Response> => {
  const url = `api/v1/landing/${affiliationName}/branding`;

  try {
    const authToken = await getAuthTokenAsync();
    const response = await apiFetch(authToken, url, {
      method: 'GET',
      headers: { 'Accept-Language': locale },
    });

    await checkStatus(response);

    const { branding } = await response.json();

    return { branding: mapBranding(branding) };
  } catch (error) {
    handleError('unexpected status - v1 get landing branding', error);
    return { branding: null };
  }
};

export default getAffiliationBrandingAsync;
