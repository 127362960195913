import React, { ReactNode, FunctionComponent, useContext } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import { BrandingContext } from './BrandingProvider';
import en from '../../locales/en';
import es from '../../locales/es';
import getAffiliationBrandingAsync from '../../api/branding/getAffiliationBrandingAsync';

const ENGLISH = 'en';
const SPANISH = 'es';

const messages: Record<string, Record<string, string>> = {
  [ENGLISH]: en,
  [SPANISH]: es,
};

const IntlContext = React.createContext({ changeLanguage: () => {} });

const IntlConsumer = IntlContext.Consumer;

interface Props {
  children: ReactNode;
}

const IntlProviderWrapper: FunctionComponent<Props> = ({ children }: Props) => {
  const router = useRouter();
  const { affiliation } = router.query;
  const { asPath, locale, defaultLocale } = router;
  const { changeBrandingContent } = useContext(BrandingContext);

  const initialLocale = locale || defaultLocale || ENGLISH;

  const cache = createIntlCache();

  let intl = createIntl(
    { locale: initialLocale, messages: messages[initialLocale] },
    cache
  );

  const changeLanguage = async (): Promise<void> => {
    const newLocale: string = locale === ENGLISH ? SPANISH : ENGLISH;

    if (typeof affiliation !== 'undefined') {
      const { branding } = await getAffiliationBrandingAsync(
        affiliation as string,
        newLocale
      );
      if (branding !== null) {
        const { content } = branding;
        changeBrandingContent(content);
      }
    }

    intl = createIntl(
      { locale: newLocale, messages: messages[newLocale] },
      cache
    );
    document.documentElement.lang = newLocale;
    router.push(asPath, asPath, { locale: newLocale });
  };

  return (
    <IntlContext.Provider value={{ changeLanguage }}>
      <RawIntlProvider value={intl}>{children}</RawIntlProvider>
    </IntlContext.Provider>
  );
};
export default IntlProviderWrapper;

export { IntlContext, IntlConsumer };
